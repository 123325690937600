import { Col, Row } from "react-grid-system";

import styles from "./Contact.module.scss";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { useState } from "react";

export const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [sent, setSent] = useState(false);

  const EMAIL_ADDRESS_VALIDATOR =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <section id="contact" className={styles.main}>
      <div className="mainWrapper">
        <Row>
          <Col md={7} sm={8} className={styles.wrapper}>
            <h1 className={styles.title}>
              <span>Contacta</span>
              <br />
              <span className="sub-md">con nosotras</span>
            </h1>
            <form
              className={styles.form}
              onSubmit={handleSubmit((data) => {
                console.log(data);
                fetch("./php/sendMail.php", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(data),
                }).then(() => {
                  setSent(true);
                  setTimeout(() => {
                    reset();
                    setSent(false);
                  }, 3000);
                });
              })}
            >
              <Row>
                <Col md={6}>
                  <div className={cn("input", { "is-invalid": errors.name })}>
                    <label htmlFor="name" className="input_label">
                      Nombre
                    </label>
                    <input
                      id="name"
                      placeholder="Introduce tu nombre"
                      className="input_control input_input"
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div className={cn("input", { "is-invalid": errors.email })}>
                    <label htmlFor="email" className="input_label">
                      Email
                    </label>
                    <input
                      id="email"
                      placeholder="Introduce tu correo electrónico"
                      className="input_control input_input"
                      {...register("email", {
                        required: true,
                        pattern: EMAIL_ADDRESS_VALIDATOR,
                      })}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="phone" className="input_label">
                      Teléfono
                    </label>
                    <input
                      id="phone"
                      placeholder="Introduce tu teléfono (opcional)"
                      className="input_control input_input"
                      {...register("phone")}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div
                    className={cn("input", { "is-invalid": errors.message })}
                  >
                    <label htmlFor="message" className="input_label">
                      Mensaje
                    </label>
                    <textarea
                      id="message"
                      placeholder="Introduce tu mensaje aquí"
                      className="input_control input_textarea"
                      {...register("message", { required: true })}
                    />
                  </div>
                  <div className={styles.cta}>
                    <input
                      className={cn("button", { "is-submitted": sent })}
                      type="submit"
                      value={sent ? "Enviado" : "Enviar"}
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
};
