import styles from "./Footer.module.scss";
import logo from "../images/logo_inverted.svg";
import cn from "classnames";
import { Col, Row } from "react-grid-system";
import { jumpTo } from "../functions/utils";

export const Footer = () => (
  <section className={styles.main}>
    <footer className={cn(styles.footer, "mainWrapper")}>
      <Row>
        <Col>
          <ul className={styles.listPrimary}>
            <li>
              <img className={styles.logo} src={logo} alt="Gallur Montesinos" />
            </li>
            <li className={styles.listTitle}>
              <p>Abogado</p>
              <p>Administración de Fincas</p>
              <p>Asesoría</p>
            </li>
            <li>
              Calle Poeta Zorrilla 10, local
              <br />
              03610, Petrer (Alicante)
            </li>
            <li>© 2023 Gallur Montesinos</li>
          </ul>
        </Col>
        <Col md={3}>
          <ul className={styles.listSecondary}>
            <li className={styles.listTitle}>Contacto</li>
            <li className={styles.iconPhone}>865 710 494</li>
            <li className={styles.iconPhone}>617 403 666</li>
            <li className={styles.iconMessage}>info@gallurmontesinos.com</li>
          </ul>
        </Col>
        <Col md={3}>
          <ul className={styles.listSecondary}>
            <li className={styles.listTitle}>Secciones</li>
            <li>
              <a onClick={() => jumpTo("header")}>Inicio</a>
            </li>
            <li>
              <a onClick={() => jumpTo("services")}>Nuestros servicios</a>
            </li>
            <li>
              <a onClick={() => jumpTo("about")}>Quienes somos</a>
            </li>
            <li>
              <a onClick={() => jumpTo("team")}>Equipo</a>
            </li>
            <li>
              <a onClick={() => jumpTo("contact")}>Contacto</a>
            </li>
          </ul>
        </Col>
      </Row>
    </footer>
  </section>
);
