import { Header, About, People, Contact, Services, Footer } from "./components";

function App() {
  return (
    <>
      <Header />
      <Services />
      <About />
      <People />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
