import { Col, Row } from "react-grid-system";

import styles from "./About.module.scss";
import cn from "classnames";

export const About = () => (
  <section id="about" className={styles.main}>
    <div className={cn(styles.wrapper, "mainWrapper")}>
      <h1 className={styles.title}>
        <span className="sub-lg">Quienes</span>
        <br />
        <span>Somos</span>
      </h1>
      <Row direction="row-reverse">
        <Col xl={5} sm={7} xs={12} className={styles.content}>
          <p>
            Lo importante no es quienes somos sino en que podemos ayudarte.
            Nuestro objetivo es mejorar tu bienestar y defender tus intereses
            con profesionalidad, dedicación y transparencia.
          </p>
          <p>
            Administramos comunidades de Propietarios desde el 2008. Nuestros
            años de experiencia nos hacen ser la mejor elección para mejorar tu
            comunidad de propietarios en todas sus facetas, con la tranquilidad
            de contar en todo momento con un equipo profesional que te ofrecerá
            una atención personalizada y cercana, capaz de dar soluciones
            eficaces a las distintas situaciones que puedan generarse en el día
            a día de la comunidad. Somos licenciados en Derecho y Administración
            de Empresas lo que es un plus de garantía para tu comunidad de
            propietarios.
          </p>
          <p>
            Tenemos abogados y asesores especialista en Derecho Civil y
            Tributario por lo que estaremos encantados de resolver cualquier
            asunto legal que se te presente con total confidencialidad y
            dedicación.
          </p>
        </Col>
      </Row>
    </div>
  </section>
);
