import { Col, Row } from "react-grid-system";

import styles from "./Services.module.scss";
export const Services = () => {
  return (
    <section id="services" className="mainWrapper">
      <div className={styles.main}>
        <Row className={styles.titles}>
          <Col md={8} offset={{ md: 1 }}>
            <h1 className={styles.title}>
              <span className="sub-md">Nuestros</span>
              <br />
              <span>Servicios</span>
            </h1>
            Administración de Fincas
          </Col>
          <Col md={2}>Servicio Jurídico y tributario</Col>
        </Row>
        <Row className={styles.blocks}>
          <Col md={2} offset={{ md: 1 }} className={styles.block}>
            <p className={styles.blockTitle}>Atención personalizada</p>
            <p className={styles.blockContent}>
              El trato con el cliente es primordial en nuestro despacho
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>
              Reducción de gastos en la comunidad
            </p>
            <p className={styles.blockContent}>
              Analizamos detalladamente los costes de la comunidad y negociamos
              los contratos de mantenimiento
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>
              Gestión económica transparente y sin comisiones
            </p>
            <p className={styles.blockContent}>
              Cobramos exclusivamente por nuestro trabajo
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>
              Garantía de administrador colegiado
            </p>
            <p className={styles.blockContent}>
              El administrador de fincas colegiado además de tener acreditada su
              cualificación también está cubierto por el seguro de
              responsabilidad civil
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>Especialistas en Derecho civil</p>
            <p className={styles.blockContent}>
              Especialistas en derecho de familia y elaboración de contratos
            </p>
          </Col>
          <Col md={2} offset={{ md: 1 }} className={styles.block}>
            <p className={styles.blockTitle}>Alta de comunidades</p>
            <p className={styles.blockContent}>
              Legalizamos tu comunidad, creamos CIF y libro de Actas
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>Gestión de cobros y morosidad</p>
            <p className={styles.blockContent}>
              Somos abogados en ejercicio por lo que controlamos la morosidad
              tanto extrajudicialmente como judicialmente sin coste adicional
            </p>
          </Col>
          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>
              Contabilidad de la comunidad llevada diariamente
            </p>
            <p className={styles.blockContent}>
              Tener la contabilidad al día es fundamental para una buena gestión
              de la comunidad de propietarios
            </p>
          </Col>
          <Col md={2} className={styles.block} />

          <Col md={2} className={styles.block}>
            <p className={styles.blockTitle}>
              Especialistas en Derecho tributario
            </p>
            <p className={styles.blockContent}>
              Especialistas en asesoría de Pymes, Rentas de las personas físicas
              e Impuesto de Sucesiones y Donaciones
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
};
