import styles from "./Header.module.scss";
import logo from "../images/logo.svg";
import cn from "classnames";
import { jumpTo } from "../functions/utils";

export const Header = () => (
  <section id="header" className={styles.main}>
    <header className={cn(styles.header, "mainWrapper")}>
      <img className={styles.logo} src={logo} alt="Gallur Montesinos" />
      <nav>
        <ul className={styles.navList}>
          <li>
            <a onClick={() => jumpTo("services")}>Servicios</a>
          </li>
          <li>
            <a onClick={() => jumpTo("about")}>Quienes somos</a>
          </li>
          <li>
            <a onClick={() => jumpTo("team")}>Equipo</a>
          </li>
          <li>
            <a onClick={() => jumpTo("contact")}>Contacto</a>
          </li>
        </ul>
      </nav>
    </header>
    <div className="mainWrapper">
      <div className={styles.content}>
        <h1 className={styles.title}>
          Profesionalidad, dedicación y confianza
        </h1>
        <h2 className={styles.subtitle}>
          Somos Gallur Montesinos, tu{" "}
          <span className="highlight">Administrador de fincas</span> en Petrer
        </h2>
        <p className={styles.contact}>
          <a onClick={() => jumpTo("contact")}>Contacto</a>
        </p>
      </div>
    </div>
  </section>
);
