import { Col, Row } from "react-grid-system";

import styles from "./People.module.scss";
import marisa from "../images/marisa.png";
import saray from "../images/saray.png";

export const People = () => (
  <section id="team" className={styles.main}>
    <div className="mainWrapper">
      <Row gutterWidth={64}>
        <Col md={6} className={styles.block}>
          <figure className={styles.placeholder}>
            <img className={styles.image} src={marisa} alt="Marisa Gallur" />
            <figcaption className={styles.caption}>
              <span>Marisa</span>
              <br />
              <span className="sub-sm">Gallur</span>
            </figcaption>
          </figure>
          <div className={styles.description}>
            <p>
              <strong>Licenciada en Derecho</strong> por la Universidad de
              Alicante. Abogada Colegiada.
            </p>
            <p>
              <strong>Administradora de Fincas</strong> Colegiada por
              el Colegio de Administradores de Fincas de Alicante
            </p>
          </div>
        </Col>
        <Col md={6} className={styles.block}>
          <figure className={styles.placeholder}>
            <img className={styles.image} src={saray} alt="Saray Gallur" />
            <figcaption className={styles.caption}>
              <span>Saray</span>
              <br />
              <span className="sub-sm">Gallur</span>
            </figcaption>
          </figure>
          <div className={styles.description}>
            <p>
              <strong>
                Licenciada en Administración y Dirección de Empresas
              </strong>{" "}
              por la Universidad de Alicante
            </p>
            <p>
              Especialización en materia tributaria mediante{" "}
              <strong>Máster de Tributación</strong> de la Universidad de
              Alicante
            </p>
          </div>
        </Col>
      </Row>
    </div>
  </section>
);
